.field {
    margin-bottom: 20px;
    input, textarea {
    	width: 100%;
    }
}

#sending-contact{
	margin-top: 20px;
	margin-right: 70px;
	opacity: .5;
	float: right;
}

@media (max-width: 992px){
	.send {
	    width: 100%;
	    margin-top: 0px;
	}

	.text-xs-center {
        text-align: center;
    }

    .g-recaptcha {
        display: inline-block;
    }

    #sending-contact {
    	text-align: center;
    	float: none;
    	margin-right: 0px;
    }

    .contact-button-wrap {
    	margin-top: 20px;
    	text-align: center;
    }
}