.fa-clock,
.fa-map-marked-alt,
.fa-wrench,
.fa-globe,
.fa-star,
.fa-envelope,
.fa-tooth,
.fa-cog {
    opacity: 0.3;
    margin-right: 10px;
    font-size: 15px;
}

.fa-map-marked-alt, .fa-wrench, .fa-star {
    margin-right: 6px;
}