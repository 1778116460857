.content {
    position: relative;
}

@keyframes show-up {
    from {
        opacity:0;
        transform:translateX(-1.5em);
    }
    to {
        opacity:1;
        transform:translateX(0);
    }
}


.title {
    font-weight: normal;
    margin-bottom: 15px;
    font-size: 30px;
}
.title .fa {
    opacity: .1;
    font-size: 26px;
    margin-right: 17px;
}
.title-spacing {
	margin-bottom: 20px;
}
.subtitle {
    font-size: 16px;
    margin-bottom: 45px;
    padding-left: 44px;
    font-style: italic;
    color: rgba(0, 0, 0, 0.8);
}


.content ul {
    list-style-type: none;
    padding-left: 1em;
    margin-bottom: 40px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-left: 4px solid rgba(0, 0, 0, 0.1) !important;
}
.content ul:hover {
    padding-left: 1.3em;
    border-left: 4px solid #000 !important;
}

@media (max-width: 992px){
    .content ul {
        padding-left: .5em;
    }
}


.bigger {
    font-size: 27px;
}

.smaller {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;

    .fa {
        float: left;
        margin-top: 4px;
    }

    .sub-text{
        display: block;
        overflow: hidden;
    }
}




