body {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 300 !important;
    overflow-x: hidden;
    padding-top: 55px;
    padding-bottom: 100px;
    overflow-y: scroll;
    background: #eee url('../static/bg.png') no-repeat fixed center center / cover; 
    color: #000;
}

a {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a, a:hover, a:active, a:focus {
    text-decoration: none;
    outline: none;
    outline-offset: 0;
}

body a,
body a:hover,
body a:active,
body a:focus{
    color: #000;
}

h1, h2 {
    font-weight: 300;
}

h1 {
    font-size: 60px;
}
h2 {
    font-size: 36px;
}

body, nav, h1, h2{
    font-family:Roboto;
    font-weight:normal;
    font-style:normal;
}

img{
    max-width: 100%;
}

input, textarea {
    font-size: 18px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background-color: transparent;
    padding: 17px 30px;
    color: #000;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

body input, body textarea{border: 1px solid rgba(0, 0, 0, 0.25); color: #000;}
body input:focus, body textarea:focus {
    border: 1px solid #000;
}
