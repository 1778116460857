@import "elements";
@import "navbar";
@import "btn";
@import "contents";
@import "form";
@import "icons";
@import "about";

.side-image {
  position: fixed;
  top: 0;
  right: -30px;
  width: 100vh;
  height: 90vh;
  z-index: -1;
  background-image: url("../static/megan-photo.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

@media (max-width: 992px) {
  .side-image {
    background-image: none;
  }
}

.ml-1 {
  margin-left: 0.5rem;
}
