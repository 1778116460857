
.main-title {
    font-style: inherit;
    font-weight: 300;
}

.main-name {
    font-style: bold;
    font-weight: 700;
}

.main-subtitle {
    margin-top: 10%;
    font-weight: 300;
}

.btn-resume {
	margin-top: 3%;
}

.download-buttons{
    
}

.btn-resume {
    margin-right: 20px;
}

@media (max-width: 1200px){
    .btn-resume{
        display: block;
        max-width: 300px;
    }

    .main-subtitle {
        width: 90%;
    }
}

@media (max-width: 1080px){
    .main-subtitle {
        width: 85%;
    }
}


@media (max-width: 992px){
    .btn-resume {
    	margin-top: 30px;
    	max-width: 300px;
		display: block;
		margin-left: auto;
		margin-right: auto;
    }
    
    .main-subtitle {
        width: 100%;
    }
}