.btn {
    outline: none;
    display: inline-block;
    position: relative;

    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    font-size: 18px;
    font-family: inherit;
    font-weight: 300;

    color: inherit;
    cursor: pointer;

    padding: 17px 60px;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn {
    border: 1px solid #000;
    color: #000;

    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

    &:hover, &:active {
        box-shadow: none !important;
    }
}
