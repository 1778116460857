
.mesmall {
	height: 120px;
	width: 120px;
	border: 4px solid rgba(0, 0, 0, 0.1);
}

.mesmall-megan {
	display: none;
}

.mesmall-tooth{
	display: block;
}

.myname {
	font-size: 36px;
	font-weight: 300;
	position: relative;
	z-index: -1;
}

.line-break{
	border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
	position: relative;
	left: -70px;
	z-index: -1;
	margin-bottom: -1px;
}


.navbar-default {
	background-color: transparent;
	background-image: none;
	border-color: transparent;
	box-shadow: none;
}

.nav.navbar-nav{
	float: inherit;
	text-align: right;
}

nav {
	ul {
		list-style-type: none;

		li {
			display: inline-block;
			margin-right: 15px;

			a {
				color: rgba(0, 0, 0, 0.3);
				border-top: 1px solid transparent;
				padding-top: 18px;
				font-weight: 300;
				text-shadow: none !important;
				transition: all 1s ease;

				&.active, &:hover {
					border-top: 1px solid #000;
				}
			}
		}
	}

	.navbar-nav {
			li {
				a{
					color: rgba(0, 0, 0, 0.2);
				}
				a:hover{
					color: #000;
				}
				.active{
					color: #000;
					font-weight: bold;
				}
			}
	}
}


.navbar-collapse {
	border-color: transparent;
	padding-left: 0px;
}

.navbar-toggle {
	position: relative;
	padding: 10px;
	margin-top: 17px;
	margin-right: 30px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: 1px solid rgb(0, 0, 0);
	border-radius: 4px;
	float: right;
}

.navbar-default {
	.navbar-toggle {
		border-color: rgba(0, 0, 0, 0.5);

		.icon-bar {
			background-color: rgba(0, 0, 0, 0.5);
		}

		&:focus{
			background: transparent;
		}

		&:hover{
			background: transparent;
			border-color: #000;

			.icon-bar{
				background: #000;
			}
		}
	}
	
}


.navbar-nav {
	text-align: center;
}

@media (max-width: 992px){

	.mesmall-megan {
		display: block;
	}

	.mesmall-tooth{
		display: none;
	}

	nav{
		left: 0;
		padding: 0 15px;
	}
	nav ul li{
		margin-right: 0;
	}
	.navbar-toggle {
		margin-right: 1%;
	}
	.line-break {
		left: 0px;
	}
	.myname {
		text-align: center;
	}

	.navbar-header {
		float: none;
	}
	.navbar-left,.navbar-right {
		float: none !important;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}
	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-nav {
		float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
		float: none;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.collapse.in{
		display:block !important;
	}
}